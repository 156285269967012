<template>
  <section class="servicesValues">
    <div class="servicesValues-container main">
      <img :src="$locale['service-values']" alt="Code Image" class="servicesValues-image" />
      <div class="servicesValues-cards" id="h" ref="h">
        <div class="servicesValues-card" v-for="(message, key, idx) in $locale['object']['services-values']" :key="idx">
          <h3 class="servicesValues-card-title">{{ message.title }}</h3>
          <p class="servicesValues-card-description">{{ message.desc }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    loadText(entri) {
      const { target, isIntersecting, intersectionRatio } = entri[0];
      const isVisible = intersectionRatio !== 0 && intersectionRatio !== 1 && isIntersecting;
      isVisible ? target.classList.add("active") : target.classList.remove("active");
    },
  },
  mounted() {
    const observerText = new IntersectionObserver(this.loadText, { threshold: 0.2 });
    observerText.observe(this.$refs.h);
  },
};
</script>
<style lang="scss">
.servicesValues {
  background: #000000;
  color: $shark;
  font-family: $sec_font;
  p {
    font-family: $font_text;
    font-size: 17px;
  }
  &-container {
    display: flex;
    gap: 20px;
    padding-right: 20px;
    position: relative;
  }
  &-image {
    position: absolute;
    width: calc(50% - 20px);
    max-width: 172px;
    height: 107%;
    top: -7%;
    border-top-right-radius: 12px;
    object-fit: cover;
  }
  &-cards {
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding: 40px 0 35px;
    width: 50%;
    margin-left: auto;
    color: #fff;
    transform: scale(0.9) translateX(-70%);
    opacity: 0;
    transition: 1.2s ease-in-out all;
    opacity: 0;
    &.active {
      opacity: 1;
      transform: scale(1) translateX(0);
    }
  }
  &-card {
    &-title {
      font-size: 24px;
      margin-bottom: 4px;
    }
  }
  @media (min-width: $tablet_width) {
    p {
      font-family: $font_text;
      font-size: 16px;
    }
    &-image {
      max-width: 375px;
      border-top-left-radius: 12px;
      left: 55vw;
    }
    &-cards {
      gap: 28px;
      padding: 66px 0 63px;
      margin-right: 49%;
    }
    &-card {
      padding-left: 20px;
    }
  }
  @media (min-width: $desktop_width) {
    &-cards {
      padding: 125px 0 111px;
      gap: 56px;
    }
    &-card {
      &-title {
        font-size: 36px;
      }
    }
    p {
      font-family: $font_text;
      font-size: 18px;
      font-weight: 400;
    }
  }
  @media (min-width: $tv_width) {
    &-cards {
      margin-right: 45%;
    }
    &-image {
      max-width: 460px;
    }
  }
}
</style>
