<template>
  <section class="aboutSlider" v-if="$locale">
    <div class="aboutSlider-content main">
      <div class="aboutSlider-header">
        <h2 class="aboutSlider-title" dtitle>{{ info.title }}</h2>
        <p class="aboutSlider-description">{{ info.description }}</p>
      </div>
      <div class="aboutSlider-cards">
        <!--         <VueSlickCarousel v-bind="settings" class="aboutSlider-carrousel">
          <div v-for="(card, idx) in cards" :key="idx" class="slide">
            <div class="slide-icon"><iconic :name="card.icon" /></div>
            <div class="slide-title special-title">{{ card.title }}</div>
            <div class="slide-description">{{ card.desc }}</div>
          </div>
        </VueSlickCarousel> -->
        <Carousel3d
          class="aboutSlider-carrousel"
          :controls-visible="true"
          :border="0"
          :height="300"
          :perspective="18"
          :space="340"
          :display="5"
          :width="290"
        >
          <slide :index="i" :key="i" v-for="(slide, i) in cards" class="slide">
            <figure>
              <figcaption>
                <div class="slide-icon"><iconic :name="slide.icon" /></div>
                <div class="slide-title special-title">{{ slide.title }}</div>
                <div class="slide-description">{{ slide.desc }}</div>
              </figcaption>
            </figure>
          </slide>
        </Carousel3d>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  components: { VueSlickCarousel, Carousel3d, Slide },
  data: function () {
    return {
      /*       settings: {
        arrows: true,
        infinite: true,
        slidesToScroll: 1,
        dots: false,
        cancelable: false,
        centerMode: false,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 374,
            settings: {
              initialSlide: -0.2,
              slidesToShow: 1.2,
            },
          },
          {
            breakpoint: 424,
            settings: {
              initialSlide: -0.4,
              slidesToShow: 1.4,
            },
          },
          {
            breakpoint: 500,
            settings: {
              initialSlide: -0.6,
              slidesToShow: 1.6,
            },
          },
          {
            breakpoint: 10000,
            settings: {
              centerMode: true,
              variableWidth: true,
              slidesToShow: 2,
            },
          },
        ],
      }, */
    };
  },
  computed: {
    cards() {
      return Object.values(this.$locale["object"]["about-slider-cards"]);
    },
    info() {
      return this.$locale["object"]["about-slider-info"];
    },
  },
};
</script>

<style lang="scss">
.aboutSlider {
  background: linear-gradient(180deg, rgba(226, 226, 226, 0.44) 2.6%, #c4c4c4 100%);
  &-content {
    font-family: $sec_font;
    padding: 62px 0px 74px;
  }
  &-header {
    // color: white;
    margin-bottom: 58px;
    text-align: center;
    padding: 0 20px;
  }
  &-description {
    font-family: Averta;
    margin-top: 10px;
    font-size: 17px;
  }
  &-cards {
    color: $shark;
    margin: 0 auto;
  }
  .slide {
    background: linear-gradient(124.87deg, #e2e2e2 22.53%, #b6b6b6 93.65%);
    box-shadow: 6px 6px 8px rgba(102, 102, 102, 0.3), inset 0px 0px 8px #e2e2e2;
    border-radius: $mradius;
    padding: 18px;
    height: 300px;
    margin: 0 12px;
    max-width: 255px;
    cursor: pointer;
    &-icon {
      svg {
        height: 40px;
        width: 40px;
      }
    }
    &-title {
      font-size: 16px;
      font-weight: bold;
      margin-top: 22px;
    }
    &-description {
      font-family: $font_text;
      font-size: 15px;
      font-weight: 400;
    }
  }
  /*   .slick-prev,
  .slick-next {
    z-index: 1;
    height: 238px;

    width: 54px;
    position: absolute;
    top: 50%;
  }
  .slick-prev::before,
  .slick-next::before {
    content: "";
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    top: calc(50% - 22px);
    border-radius: 50%;
    font-family: fontawesome;
    text-align: center;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
  }
  .slick-prev {
    left: 0;
    padding-left: $mpadding * 2;
    background: linear-gradient(270deg, rgba(68, 68, 68, 0) 0%, rgba(68, 68, 68, 0.73) 100%);
    &::before {
      right: 0;
    }
  }
  .slick-next {
    right: 0;
    padding-right: $mpadding * 2;
    background: linear-gradient(270deg, rgba(68, 68, 68, 0.73) 2.08%, rgba(68, 68, 68, 0) 100%);
    &::before {
      left: 0;
    }
  }
  .slick-prev::before {
    content: "\f053";
  }
  .slick-next::before {
    content: "\f054";
  }
  @media (max-width: $tablet_width - 1px) {
    .slick-next::before,
    .slick-prev::before {
      content: "";
    }
  } */
  @media (min-width: $tablet_width) {
    &-content {
      padding: 78px 0px 210px;
    }
    &-title {
      font-size: 24px;
    }
    &-header {
      margin-left: auto;
      margin-right: auto;
      max-width: 529px;
    }
  }
  @media (min-width: $desktop_width) {
    &-description {
      font-size: 16px;
      margin-top: 16px;
    }
    .slide {
      height: 263px;
      max-width: 332px;
    }
    /*     .slick-prev,
    .slick-next {
      height: 265px;
    } */
    &-header {
      margin-bottom: 110px;
      max-width: 100%;
    }
    &-title {
      font-size: 36px;
    }
    &-description {
      font-size: 16px;
    }
    &-content {
      padding: 110px 0px 211px;
    }
  }
  @media (min-width: $tv_width) {
    /*     .slick-prev {
      left: -1px;
      &::before {
        left: 10px;
      }
    }
    .slick-next {
      right: -1px;
      &::before {
        right: 2px;
        left: unset;
      }
    } */
  }
}
</style>
